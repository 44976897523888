<template>
  <b-card-code title="Account">
    <b-row>
      <b-col
        lg="4"
        class="mb-1"
      > <b-form-group
        label="Employee No."
        label-for="employeeNo"
      >

        <b-input-group>
          <b-form-input
            v-model="employeeNo"
            readonly
          />
        </b-input-group>
      </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <div
          class="employee-task d-flex justify-content-between align-items-center"
        >
          <b-media no-body>
            <b-media-aside class="mr-75">
              <b-avatar
                rounded
                size="80"
                :src="'https://www.iconext.cc/HRMS/File/GetSquareImageProfile?squareImageFileName='+employee.pictureName"
              />
            </b-media-aside>
            <b-media-body class="my-auto">
              <h6 class="mb-0">
                {{ employee.fullName }}

              </h6>
              <small>{{ employee.employeeNo }}</small>
            </b-media-body>
          </b-media>
          <div class="d-flex align-items-center">
            <small class="text-muted mr-75">{{ employee.duration }}</small>

          </div>
        </div>
      </b-col>
    </b-row>
    <br>
    <br>
    <b-row>
      <b-col
        md="6"
        xl="4"
        class="mb-1"
      >
        <!-- basic -->
        <b-form-group
          label="User Name"
          label-for="basicInput"
        >
          <b-form-input
            id="basicInput"
            readonly
            :value="employee.userAccount"
          />
        </b-form-group>
      </b-col>

      <b-col
        md="6"
        xl="4"
        class="mb-1"
      >
        <!-- basic -->
        <b-form-group
          label="Position"
          label-for="basicInput"
        >
          <b-form-input
            id="basicInput"
            readonly
            :value="employee.positionName"
          />
        </b-form-group>
      </b-col>

      <b-col
        md="6"
        xl="4"
        class="mb-1"
      >
        <!-- basic -->
        <b-form-group
          label="Email"
          label-for="basicInput"
        >
          <b-form-input
            id="basicInput"
            readonly
            :value="employee.email"
          />
        </b-form-group>
      </b-col>

      <b-col
        md="6"
        xl="4"
      >
        <!-- with helper text -->
        <b-form-group
          label="User Role"
          label-for="helperInput"
        >
          <v-select
            v-model="employee.userGroupName"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="optionUserGroupId"
            label="text"
            :disabled="true"
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl="4"
      >
        <!-- readonly input -->
        <b-form-group
          label="Status"
          label-for="readOnlyInput"
        >
          <b-form-checkbox
            v-model="employee.isActive"
            value="true"
            class="custom-control-success"
            :disabled="true"
          >
            Active
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>
    <div class="demo-inline-spacing">

      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="submit"
        @click="goBack()"
      >
        Back
      </b-button>
    </div>
    <template #code>
      {{ codeButton }}
    </template>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BFormInput, BRow, BCol, BFormGroup, BButton, BMedia, BMediaAside, BAvatar, BMediaBody, BInputGroup, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
/* eslint-disable global-require */

import vSelect from 'vue-select'

import useJwt from '@/auth/jwt/useJwt'
import { codeButton } from './code'

export default {
  components: {
    BCardCode,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BInputGroup,
    vSelect,
    BFormCheckbox,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      invalid: false,

      employeeNo: '',
      employee: {},
      userGroupId: 0,
      codeButton,
      optionUserGroupId: [],

    }
  },
  mounted() {
    useJwt.getUserGroups()
      .then(res => { this.optionUserGroupId = res.data.list })
    this.employeeNo = this.$route.params.employeeNo
    this.showDetail(this.employeeNo)
  },
  methods: {
    showDetail() {
      useJwt.getUser(this.employeeNo).then(res => {
        this.employee = res.data.user
      })
    },
    goBack() {
      this.$router.push({ name: 'user-management' })
    },

  },
}
/* eslint-disable global-require */
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
